import React from "react"
import Layout from "../components/layout"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"
import LogoCard from "../components/logo-card"
import FACard from "../components/fa-card"

export default function Policies() {
  return ( 
    <Layout>
        <section className="container">
        <h1>Policies</h1>
        <InnerPageTwitterLayout>
            <img className="img-fluid mb-4 inner-page-hero" src="stock-respect2.jpg" />
            <p className="lead">We all have a responsbility to promote high standards of behaviour in the Game.</p>
            <p>Please see links to our clubs policies and other key documents. You are encouraged to familiarise yourselves with these documents as an important contribution to the maintenance of standards within our club.</p>
            <div className="row mt-4">
                <div className="col-md-6">
                    <FACard title="Safeguarding Children Policy (youth teams)" category="FA Policies" download={true} href="/downloads/1-2-grassroots-football-safeguarding-children-policy-procedures-colour-version.pdf"></FACard>
                </div>
                <div className="col-md-6">
                    <FACard title="Safeguarding Children Policy (adult/U18)" category="FA Policies" download={true} href="/downloads/18-the-fas-safeguarding-adults-regulations-colour-version.pdf"></FACard>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FACard title="Equality Policy" category="FA Policies" download={true} href="/downloads/fas_equality-policy.pdf"></FACard>
                </div>
                <div className="col-md-6">
                    <FACard title="Respect Code of Conduct" category="FA Policies" download={true} href="/downloads/fa-respect-code-of-conduct-spectators-and-parents.pdf"></FACard>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FACard title="Anti Bullying Policy" category="FA Policies" download={true} href="/downloads/16-antibullying-policy-template-editable-colour-version-2.pdf"></FACard>
                </div>
                <div className="col-md-6">
                    <FACard title="Club Rules / Constitution" category="FA Policies" download={true} href="/downloads/fa-club-constitution.pdf"></FACard>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FACard title="Football Leadership Diversity Code" category="FA Policies" download={true} href="/downloads/football-leadership-diversity-code-final.pdf"></FACard>
                </div>
            </div>
        </InnerPageTwitterLayout>
        </section>
    </Layout>
  );
}
